import React from 'react'
import styled from '@emotion/styled'
import { Box, Button, Modal, ModalProps } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onClose: () => void
  style?: any
  labelClose?: string
  errorDetail: string
}

// limite del numero di caratteri della stringa di errore che fanno scattare la dimensione large della modale
const BUMP_ERROR_SIZE = 80

export const ErrorDetailsModal = (props: Props) => {
  const { isOpen, onClose, title, subtitle, labelClose, errorDetail } = props

  const btn1 = { flex: 1, minWidth: 'auto', height: 60 }

  const size: ModalProps['size'] = errorDetail && errorDetail.length > BUMP_ERROR_SIZE ? 'lg' : 'md'

  return (
    <Modal visible={isOpen ?? true} onClose={onClose} size={size}>
      <Box>
        <Title>{title}</Title>
      </Box>

      <Box>
        <ErrorDetail>{errorDetail}</ErrorDetail>
      </Box>

      <Box row mt={10}>
        <Button variant="default" title={labelClose || __(T.misc.ok)} onClick={onClose} style={btn1} />
      </Box>
    </Modal>
  )
}

const Title = styled.span`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
`
const ErrorDetail = styled.div`
  padding: 30px 10px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
`
