import { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { Router, ShippingOperationConfig, RemoteOperation, Sounds } from 'stylewhere/shared'
import { askUserConfirmation, isModalError, hasChecklist, showToast, showToastError } from 'stylewhere/utils'
import { Shippings, ShippingParcel, AsnStatus, ParcelStatus, Asn } from 'stylewhere/api'
import type { Routes } from 'stylewhere/pages'
import { T, __ } from 'stylewhere/i18n'
import { Box, Icons, Input, List, Page, ParcelRow, ParcelRightHeader, Button } from 'stylewhere/components'

export interface ShippingParcelStartParams {
  opCode: string
  asnId: string
}

export interface ShippingParcelStartState {
  // showForm: boolean
  searching: string
  parcels?: ShippingParcel[]
  loading: boolean
  error: boolean
  currentListPage: number
  totalPages: number
  totalElements: number
  asn?: Asn
}

const LIST_PAGE_SIZE = 100

export const getParcelReadingStatuses = (operationConfig: ShippingOperationConfig): ParcelStatus[] => {
  if (operationConfig.type === 'INBOUND')
    return ['DRAFT', 'IN_TRANSIT', 'FORCE_SENT', 'IN_INBOUND', 'INBOUND_ERROR', 'FORCE_RECEIVED']
  if (operationConfig.type === 'OUTBOUND') return ['DRAFT', 'PACKED', 'IN_OUTBOUND', 'OUTBOUND_ERROR']
  return []
}

export const getShipmentAddParcelStatuses = (operationConfig: ShippingOperationConfig): AsnStatus[] => {
  if (operationConfig.type === 'INBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_INBOUND', 'INBOUND_ERROR']
  if (operationConfig.type === 'OUTBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_OUTBOUND', 'INBOUND_ERROR']
  return []
}

export default class ShippingParcelStart<
  P extends RouteComponentProps<ShippingParcelStartParams>,
  S extends ShippingParcelStartState
> extends Component<P, S> {
  asnId: string
  opCode: string
  isModal: boolean
  operation: ShippingOperationConfig
  backPath: Routes = '/shipping/:opCode' // FIXME
  readingPath: Routes = '/shipping/:opCode/reading'
  selfPath: Routes = '/shipping/:opCode/asn/:asnId'
  addParcelPath: Routes = '/shipping/:opCode/asn/:asnId/add'
  selectParcelPath: Routes = '/shipping/:opCode/asn/:asnId/add/:parcelId'
  state: S = {
    loading: false,
    rowLoading: {},
    rowExpanded: {},
    parcelsDetails: {},
    currentListPage: 0,
    totalElements: 0,
    totalPages: 0,
    error: false,
    searching: ''
  } as any

  constructor(props: any) {
    super(props)
    const matchParams: ShippingParcelStartParams = Router.getMatchParams(this.props)
    this.asnId = matchParams.asnId
    this.opCode = matchParams.opCode
    this.isModal = false
    this.operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.opCode)
  }

  canShowAddParcel() {
    const { asn } = this.state
    return (
      asn &&
      asn.status &&
      getShipmentAddParcelStatuses(this.operation).includes(asn.status) &&
      this.operation.canCreateParcels
    )
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    await this.loadAsn(this.asnId)    
  }

  async loadAsn(id: string) {
    try {
      // FIXME: save asn on route state and get from there when available
      const asn = await Shippings.getAsnExtended(id)
      this.setState({ asn }, this.loadAsnParcel)
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  filterAsnParcel = async (search?: string) => {
    this.setState({ loading: true, error: false, searching: search ?? '', parcels: [] }, this.loadAsnParcel)
  }
    

  loadAsnParcel = async () => {
    const { currentListPage, searching, asn, parcels } = this.state
    try {
      const skip = !asn
      if (!skip) {
        const parcelsResponse = await Shippings.getParcelListByAsn(
          this.operation,
          asn.code,
          LIST_PAGE_SIZE,
          currentListPage,
          searching,
          this.operation.reverseParcelOrder ? 'asc' : 'desc',
          this.operation.filterByOperation
        )
        const { totalPages, totalElements } = parcelsResponse
        const tmp: ShippingParcel[] = (parcels ?? []).concat(parcelsResponse.content)
        this.setState({
          loading: false,
          parcels: tmp,
          totalPages,
          totalElements,
        })
      }
    } catch (error) {
      this.setState({ loading: false, error: true })
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  isParcelNotCompleted(parcel: ShippingParcel): boolean {
    return getParcelReadingStatuses(this.operation).includes(parcel.status)
  }

  isItems = () => {
    return this.operation.readingsDisplayMode === 'item'
  }

  onNewParcel = () => {
    Router.navigate(
      this.addParcelPath,
      { opCode: this.operation.code, asnId: this.asnId },
      {
        state: {
          asn: this.state.asn,
        },
      }
    )
  }

  getOnConfirmShipmentCB = (askConfirmation: boolean) => async () => {
    const { asn } = this.state
    if (!asn) {
      showToastError(__(T.misc.shipment), __(T.error.error), this.isModal)
      return
    }

    try {
      if (askConfirmation) {
        const ok = await askUserConfirmation(
          __(T.confirm.confirm_close_asn_title),
          __(T.confirm.confirm_close_asn_text)
        )

        if (!ok) return
      }

      await Shippings.confirmAsn(this.operation, asn.code, {
        transactionDate: new Date().toISOString(),
      })

      showToast({
        title: __(T.misc.success),
        description: __(T.messages.operation_success),
        status: 'success',
      })
      Sounds.success()

      Router.navigate(this.backPath, { opCode: this.operation.code })
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  parcelRowRender = (parcel: ShippingParcel) => {
    return <ParcelRow expandable={!this.isParcelNotCompleted(parcel)} parcel={parcel} operation={this.operation} asn={this.state.asn} />
  }

  getAsnCounter = () => {
    const { parcels, asn } = this.state

    let expected
    let detected
    if (asn && asn.detectedCounter) {
      detected = asn.detectedCounter
    } else {
      detected =
        (parcels ?? []).reduce((acc, curr) => {
          return acc + (curr.parcelEntryQuantity ?? 0)
        }, 0) ?? 0
    }

    if (asn && asn.expectedCounter) {
      expected = hasChecklist(this.operation) ? asn.expectedCounter : undefined
    } else {
      expected = hasChecklist(this.operation)
        ? (parcels ?? []).reduce((acc, curr) => {
            return acc + (curr.checklistExpectedQuantity ? curr.checklistExpectedQuantity : 0)
          }, 0) ?? 0
        : undefined
    }

    const counter = {
      detected: detected,
      expected: expected,
    }
    return counter
  }

  render() {
    const { parcels, loading, asn } = this.state
    const canShowConfirmShipment = parcels && parcels.length > 0 ? true : false
    const counter = this.getAsnCounter()

    let askCloseConfirm = false
    if (canShowConfirmShipment && parcels) {
      askCloseConfirm = parcels.some((parcel) => !parcel.parcelEntryQuantity)
    }

    return (
      <Page
        headerRight={
          <ParcelRightHeader
            shipmentCode={asn?.code}
            onConfirmShpment={this.getOnConfirmShipmentCB(askCloseConfirm)}
            canShowConfirmShipment={canShowConfirmShipment}
            totalExpecteds={counter.expected}
            totalReadings={counter.detected}
          />
        }
        title={this.operation.description}
        onBackPress={() => Router.navigate(this.backPath, { opCode: this.opCode })}
      >
        <Box bg={'#FFF'} flex>
          <Page.Content notBoxed>
            <Box p={0} pb={0} mb={40} row justify={'space-between'}>
              <Input
                onChange={this.filterAsnParcel}
                placeholder={`${__(T.misc.parcel)}`}
                image={<Icons.Barcode />}
                style={{ width: 325 }}
                // autoFocus - blur bug
                debounce={300}
                disabled={loading}
              />
              {this.canShowAddParcel() && (
                <Button title={`${__(T.misc.new_parcel)}`} disabled={false} onClick={this.onNewParcel} />
              )}
            </Box>
            <List
              title={`${__(T.misc.parcels)} (${parcels?.length ?? 0})`}
              loading={loading}
              data={parcels}
              rowRender={(parcel: ShippingParcel) => {
                return this.parcelRowRender(parcel)
              }}
            />
          </Page.Content>
        </Box>
      </Page>
    )
  }
}
