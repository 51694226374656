export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'User Info',
    settings: 'Settings',
    reset_daily_items: 'Reset daily items?',
    last_parcels: 'Last Parcels',
    select_item: 'Select Item',
    select_workstation: 'Select workstation',
    select_language: 'Select language',
    support: 'Support',
    select_printer: 'Select printer',
    shipment_to_confirm: 'Shipment to Confirm',
    sorting_details: 'Ventilation details',
    inventories: 'Inventories',
    pin_modal: 'Enter the pin to force association',
    shipments: 'Shipments',
    report_problem: 'Report a problem',
    parcels: 'Parcels',
    //moncler
    outbound_bthp: 'Outbound BTHP Certificate'
  },
  simple_parcel_list: {
    to_read: 'To Read',
    ok: 'Ok',
    force: 'Force',
    force_parcel: 'Force parcel {{code}}',
    force_parcel_desc: 'Insert user PIN to force parcel',
    forced: 'Forced',
    unexpected: 'Unexpected',
    remove: 'Remove',
    invalid_pin: 'User PIN invalid',
    loading: 'Loading parcels list'
  },
  create_parcel_gate: {
    //moncler
    width: 'Width',
    height: 'Height',
    depth: 'Depth',
    tare: 'Tare',
    description: 'Description'
  },
  fields: {
    current_location: 'Current Location',
    identified_item: 'Identified Item',
    ignored_items: 'Ignored Items',
    ignored_with_reason_items: 'Ignored with reason Items',
    error_items: 'Error Items',
    warning_items: 'Warning Items',
    umg: 'Umg',
    custom_class: 'Custom class',
    product_code: 'Product code',
    as400username: 'Username AS400',
    item_components: 'Components',
    //valentino
    mission: 'Mission',
    mission_type: 'Mission type',
    supplier: 'Supplier',
    physical_storage: 'Physical storage',
    season: 'Season',
    ddt: 'DDT',
    notification: 'Notification',
    ddt_date: 'DDT Date',
    notification_date: 'Notification Date',
    product_category: 'Product category'
  },
  misc: {
    or: 'or',
    select_place: `Ciao {{user}}, select your places of origin to continue`,
    back: 'Back',
    refresh: 'Refresh',
    reload: 'Reload',
    save: 'Save',
    confirm: 'Confirm',
    confirm_auto: 'Auto-confirm',
    update_and_confirm: 'Update and confirm',
    update: 'Update',
    no_carton_found: 'No carton found',
    confirm_continue: 'Confirm and continue',
    cancel: 'Cancel',
    close: 'Close',
    user: 'User',
    workstation: 'Workstation',
    username: 'Username',
    name: 'Name',
    password: 'Password',
    login: 'Login',
    encoding: 'Encoding',
    code: 'Code',
    ean_code: 'EAN Code',
    certilogo: 'Certilogo',
    enter_the_necessary_fields: 'Enter the necessary fields to start the operation',
    associated_items: 'Associated Items',
    associate: 'Associate',
    associated: 'Associated',
    verify: 'Verify',
    reading: 'Reading',
    processing: 'Processing',
    association: 'Association',
    associations: 'Associations',
    waiting: 'Waiting',
    writing: 'Writing',
    writing_progress: 'Writing in progress...',
    partial_confirm: 'Partial Confirm',
    retry: 'Retry',
    writing_association: 'Writing and association',
    confirmed: 'Confirmed',
    unable_to_write: `Unable to write epc tag : {{code}} for product: {{productCode}}`,
    unable_to_get_next_epc: `Unable to get next epc for: {{productCode}}`,
    unable_to_connect_to_workstation: 'Unable to connect to workstation',
    tag_info: 'Tag Info',
    inbound: 'Inbound',
    outbound: 'Outbound',
    stop: 'Stop',
    start: 'Start',
    pause: 'Pause',
    clear: 'Clear',
    tag_encoded_today: 'Tag Encoded today',
    transaction_date: 'Transaction date',
    select_transaction_date: 'Select transaction date',
    ready_to_receive: 'Ready to receive',
    change_password: 'Change Password',
    logout: 'Logout',
    are_you_sure_to_logout: 'You really want to log out?',
    settings: 'Settings',
    roles: 'Roles',
    email: 'Email',
    place: 'Place',
    items: 'Items',
    description: 'Description',
    color: 'Color',
    size: 'Size',
    part: 'Part',
    total_reads: 'TOTAL READS',
    total_ok: 'TOTAL OK',
    save_to_list: 'Save to list',
    destination: 'Destination',
    origin: 'Origin',
    new_parcel: 'New Parcel',
    status: 'Status',
    parcel: 'Parcel',
    read_parcel: 'Read Parcel',
    parcels: 'Parcels',
    shipment: 'Shipment',
    creation_date: 'Creation Date',
    search: 'Search',
    select_search: 'Search...',
    detail: 'Detail',
    quantity: 'Quantity ({{quantity}})',
    only_quantity: 'Quantity',
    style: 'Style',
    material: 'Material',
    shipment_code_auto: 'Shipment code (auto)',
    parcel_code_auto: 'Parcel code (auto)',
    next: 'Next',
    parcels_to_receive: 'Parcels to receive',
    confirm_parcel: 'Confirm Parcel',
    image_missing: 'IMAGE MISSING',
    image: 'Image',
    loading: 'Loading...',
    unknown_product: 'Unknown Product',
    unknown_tag: 'Unknown Tag',
    invalid_tag: 'Invalid Tag',
    stock: 'Stock',
    valid: 'Valid',
    unknown: 'Unknown',
    actions: 'Actions',
    reprint: 'Reprint',
    product: 'Product',
    product_items: 'Product Items',
    identifiers: 'Identifiers',
    zone: 'Zone',
    report_repair: 'Report Repair',
    reason: 'Reason',
    request_code_change: 'Request Code Change',
    enable_workstation_emulation: 'Enable Workstation emulation',
    enable_browser_print_emulation: 'Enable Browser Print emulation',
    filter: 'Filter',
    language: 'Language',
    warning: 'Warning',
    success: 'Success',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Optional',
    phone: 'Phone',
    version: 'Version',
    printer: 'Printer',
    new_shipment: 'New Shipment',
    upc: 'UPC',
    model_part_color_size: 'Model / Part / Color / Size',
    confirm_shipment: 'Confirm Shipment',
    receive: 'Receive',
    delete: 'Delete',
    delete_association: 'Delete Association',
    change: 'Change',
    from: 'From',
    to: 'To',
    ignored: 'Ignored',
    skipped: 'Skipped',
    ignored_with_reason: 'Ignored with reason',
    yes: 'Yes',
    no: 'No',
    model: 'Model',
    typology: 'Tipo',
    no_result: 'No result',
    expected: 'Expected',
    read: 'Read',
    missing: 'Missing',
    unexpected: 'Unexpected',
    unexpected_parcel: 'Unexpected parcel',
    ignored_plural: 'Ignored',
    serial: 'Serial',
    priority: 'Priority',
    group: 'Group',
    required_quantity: 'Required qty',
    sorted_quantity: 'Ventilated qty',
    missing_quantity: 'Missing qty',
    barcode: 'Barcode',
    barcode_bc: 'Barcode BC',
    sorted_date: 'Ventilation date',
    customer: 'Customer name',
    list: 'List',
    list_position: 'List Position',
    select_date: 'Select date',
    item_info: 'Item info',
    item_events: 'Item events',
    operation_type: 'Operation type',
    operation_place: 'Operation place',
    loading_data: 'Please wait, data is loading ...',
    empty_item_events: 'No events found',
    show_more: 'Show more',
    hide_more: 'Hide more',
    production_order: 'Production Order',
    production_order_row: 'Production Order Row',
    production_order_sku: 'Production Order Sku',
    kill_tag: 'Kill Tag',
    item_order: 'Item Order',
    item_row: 'Item Row',
    pack_code: 'Package Code',
    send_reads: 'Send Reads',
    items_add_inventory_success: 'Items added to inventory',
    new: 'New',
    confirm_mission: 'Confirm mission',
    confirm_mission_text: 'Are you sure you want to confirm the mission?',
    parziali: 'Partial',
    total_quantity: 'Total quantity',
    accepted_quantity: 'Accepted quantity',
    country_check: 'Country control',
    showcase_check: 'Showcase control',
    attention: 'Attention',
    clear_items: 'Proceeding will remove all readings, do you want to continue?',
    add_partial: 'Attention',
    add_partial_text: 'It may be necessary to create a Quality Assurance Mission',
    add_partial_already_text:
      'It may be necessary to create a Quality Assurance Mission. UMG already worked. Do you want to overwrite the items read?',
    error_cq_mission: 'Attention, create Mission for Quality Control',
    warning_zone_items: 'IN_ANOTHER_ZONE',
    internal_tag: 'Internal Tag',
    external_tag: 'External Tag',
    error_tag: 'Error tag',
    awaiting: 'Awaiting',
    error_logs: 'Error logs',
    event_logs: 'Event logs',
    box_closed: 'Box closed',
    unexpected_list: 'Unexpected item list',
    pending_tags: 'Pending tags',
    selected_zone: 'Selected zone',
    pricing: 'Pricing',
    send_to_sap: 'Send to SAP',
    write_tag: 'Write Tag',
    identifier_tag: 'Identifier Tag',
    digit_identifier_tag: 'Digit tag to write',
    target_epc: 'Target EPC',
    retry_write_tag: 'A writing error was encountered. Do you want to try writing the tag again?',
    ready: 'Ready',
    offline: 'Off-line',
    shipping_date: 'Shipping Date',
    update_only_product: 'Item already associated to another product. Are you sure do you want to overwrite it ?',
    export: 'Export',
    select_zone: 'Select zone',
    location_origin: 'Location origin',
    confirm_kill_tag: 'Confirm Kill Tag',
    select_tag_to_kill: 'Select the tag you want to kill',
    retry_kill_tag: 'A killing error was encountered. Do you want to try killing the tag again?',
    navBackWarning: 'Are you sure you want to go back? All unsaved changes, such as readings, will be lost.',
    sentry: {
      report_problem: 'Describe the problem you encountered or suggest an improvement'
    },
    ready_to_read: 'Ready to read',
    remove_product_readings: 'Remove product readings',
    ok: 'OK',
    already_in_parcel: 'already in parcel',
    operation_canceled_by_user: 'Operation canceled by user',
    load_more: 'loading in progress',
    input: 'Input',
    rfid_barcodes: 'RFID Barcodes',
    submit: 'Submit',
    //valentino
    error: 'Error',
    OK_WORKER: 'OK WORKER',
    KO_WORKER: 'KO WORKER',
    OK_REPAIR: 'OK REPAIR',
    RESO_RICHIESTO_CQ: 'QC REQUESTED',
    RESO_OK: 'OK RETURN',
    OK_VALDAGNO: 'OK VALDAGNO',
    KO_VALDAGNO: 'KO VALDAGNO',
    SECOND_VALDAGNO: 'SECOND CHOICE',
    OK_LINING: 'OK LINING',
    ok_first_inbound_status: 'OK FIRST INBOUND',
    OK_RISC: 'RISK',
    KO_LINING: 'KO LINING',
    size_color: 'Size/Color',
    select_size_color: 'Select size/color',
    print: 'Print',
    missings: 'Missings',
    embedded: 'Embedded',
    ignoreds: 'Ignoreds',
    select_printer: 'Select printer',
    printing_started: 'Printing started',
    release_reports: 'Release reports',
    quality_checks: 'Quality checks',
    quality_check: 'Quality check',
    total_mission_qty: 'Qta totale missione',
    inbound_read_qty: 'Qta letta ingresso varco',
    checked_qty: 'Qta controlli effettuati',
    checked: 'Collaudati',
    returned: 'Rientrati',
    damaged: 'Difetti',
    qty_damaged_first_check: 'Qta difettati 1° controllo',
    first_check_date: 'Data 1° controllo',
    qty_damaged_second_check: 'Qta difettati 2° controllo',
    second_check_date: 'Data 2° controllo',
    note: 'Note',
    //louis vuitton
    start_and_read: 'Please start the antennas and place the product'
  },
  error: {
    error: 'Error',
    errors: 'Errors',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Too many tags read (expected {{exptected}} read {{read}})',
    too_many_checklist_types: 'Too many checklist types. Make sure to select parcels with same checklist type.',
    rfid_discrepancies_found: 'RFID Discrepancies found, clear all and try again or confirm with discrepancies',
    certilogo_not_found: 'Certilogo "{{code}}" not found',
    ean_not_found: 'EAN "{{code}}" not found',
    production_order_not_found: 'Production Order "{{code}}" not found',
    no_inbound_zones: 'No Inbound zones',
    no_operation_config: 'Operation configuration not found',
    not_supported_reading_mode: 'Reading mode not supported',
    undefined_operation_config: 'Operation configuration cannot be undefined',
    certilogo_already_encoded: 'Certilogo "{{code}}" already encoded',
    tag_not_found: 'Tag not found',
    missing_printer_id: 'Missing printer IP',
    tag_unassociated: 'Tag unassociated',
    tag_type_unexpected: 'Unexpected tag type',
    tag_already_associated: 'Tag already associated. You must disassociate it first',
    tags_not_associated: 'One or more tags are not associated',
    tags_mismatch: 'Tags mismatch',
    workstation_could_not_be_started: 'Workstation {{code}} could not be started',
    authentication_failed: 'Authentication failed',
    field_required: 'Field {{label}} is required',
    unable_to_connect_to_rfid_reader: 'Unable to connect to RFID Reader. Check that the device is turned On.',
    items_in_error_found: 'Items in error found',
    item_not_in_inbound: 'Item not in inbound',
    item_not_in_stock: 'Item not in stock',
    item_in_another_parcel: 'Item in another parcel',
    item_in_another_production_order: 'Item in another production order',
    connection: 'Connection error',
    connection_details:
      'There was no response from the server. Check that your internet connection or VPN configuration are working.',
    field_invalid_default: 'Invalid default value "{{defaultValue}}" for field {{field}}',
    field_invalid_default_from_endpoint: 'Could not finda a value for field {{field}}',
    sorting_already_read: 'Item with serial {{serial}} already read in group {{group}}',
    sorting_no_group: 'Item not sortable',
    sorting_not_in_zone: 'Item with serial {{serial}} not sortable. Already in zone {{zone}}',
    sorting_unknown: 'Tag {{tag}} is not valid',
    sorting_unexpected: 'Unexpected tag',
    no_item_configuration: 'No item configuration found',
    item_creation_error: 'Item creation error',
    couldn_find_production_order: "Couldn't find production order",
    couldn_find_production_order_row: "Couldn't find production order row",
    missing_operation_option: 'Missing operation option: {{error}}',
    item_session_list_create: 'Uncreated list',
    item_session_list_create_session: 'Uncreated session list',
    item_session_list_confirm: 'Unconfirmed list',
    item_session_list_confirm_session: 'Unconfirmed session list',
    form_validation: 'Validation form',
    form_validation_message: 'Error filling in the field',
    select_place_error: "Couldn't save place of origin",
    self_user_data: "Couldn't save your data",
    encoding_no_tags: 'No further tags have been read. Operation aborted',
    only_virgin_tag: 'Only virgin tags detected',
    tags_mismatch_error: 'Please read one tag at a time',
    tags_errors: 'Tag errors',
    value_not_valid: 'The entered value is not valid',
    write_tag: 'Unknown error during write Tag',
    WRONG_PRODUCTION_ORDER: 'Wrong Production Order',
    WRONG_PLACE: 'Wrong place',
    PRODUCTION_ORDER_ROW_CREATION_ERROR: 'Production order row creation error',
    force_tag_associated: 'Tag already associated. Reassociate?',
    operation_confirm_error: 'Error confirming the operation',
    missing_mandatory_qc: 'No quality control. Proceed?',
    wrong_qc_status: 'The product has been declared KO. Proceed?',
    wrong_qc_size: 'Check size. Proceed?',
    wrong_qc_size_payload: 'Check size. Encoding Size: {{productSize}} - Physical size: {{manualSize}}. Proceed?',
    wrong_qc_model: 'Check model. Proceed?',
    start_checklist: 'Unstart checklist',
    confirm_with_unexpected: 'Cannot confirm with unexpected tags',
    confirm_with_overqty: 'Cannot confirm with over quantity tags',
    rfid_reader_initialization: 'Unknown error during RFID reader initialization',
    remove_sap_item_error: 'Unable to remove item',
    no_asn_selected: 'No ASN selected',
    submit_asn: 'Error submitting ASN',
    zbpNotFound: 'Zebra Browser Print agent not found on your system. Make sure it is installed and running',
    no_printer_selected: 'No printer selected',
    zplTemplateNotDefined:
      "ZPL template not defined. Please set a template code in the 'zpl-template-code' attribute of the operation configuration",
    no_print_template: 'Print template not found',
    wrong_tag: 'Wrong Tag',
    tag_error_or_not_authentic: 'Tag not authentic or authentication error',
    authentication_timeout: 'Authentication timeout',
    tid_missing: 'Tid for readed tag is missing',
    on_destination: 'on destination {{destination}}',
    item_not_sortable: 'Item with serial {{serial}} is not sortable',
    search_error: 'Search error',
    no_parcel_found_by_identifier: 'No parcel found with this identifiers',
    kill_tag_not_allowed:
      'It is not possible to kill a tag associated with an item. To kill the tag, first disassociate the item.',
    kill_tag_not_success: 'Kill tag failed',
    nfc_tag_not_expected: 'NFC tag not expected for this product',
    ventilation_quantity_reached: 'The quantity to be ventilated for this product as already been reached',
    confirm_not_allowed_with_errors: 'Confirmation not allowed with errors',
    close_not_allowed_with_errors: 'Close not allowed with errors',
    generic: 'An error occurred while sending the feedback',
    form_entry: 'Some fields are required',
    invalid_tag_read: 'Invalid tag read',
    read_one_tag_time: 'Read only one tag at time',
    validation_failed_rewrite: 'Validation failed for rewrite configuration',
    uhf_tag_not_found: 'UHF tag not found',
    compute_tags_failed: 'Generation of the tag to write failed',
    be_generic_error: 'System error',
    be_generic_error_description: 'There was an error in the system. Please try again later',
    //cucinelli
    nfc_already_associated: 'NFC already associated. Disassociate it and repeat the operation',
    too_many_valid_tag_read: 'Too many valid tag read',
    fields_required: ' is required'
  },
  messages: {
    no_operations_found: 'No operations found. Check the user permissions on the ControlPanel',
    not_allowed_operation: `You don't have the capability to perform this operation. Please check your configuration`,
    no_workstations_found: 'No Workstations Found',
    no_workstation_selected: 'No Workstation selected',
    are_you_sure_to_reset_daily_items: 'Are you sure you want to reset the daily items?',
    bring_an_item_to_antenna: 'Bring an item close to the antenna',
    press_start_to_read: 'Press Start button to \nstart reading RFID products',
    reading_in_progress: 'Reading in progress',
    waiting_for_product: 'Waiting for product...',
    paste_comma_separeted_tags: 'Paste comma separated tags then press return...',
    connection_workstation_progress: 'Connection to workstation in progress..',
    operation_success: 'Operation successfully confirmed',
    outbound_success: 'Outbound successfully confirmed',
    inbound_success: 'Inbound successfully confirmed',
    pack_success: 'Packing successfully confirmed',
    transfer_to_zone_success: 'Transfer To Zone successfully confirmed',
    simple_item_list_success: 'Simple Item List successfully confirmed',
    list_empty: 'List is empty',
    looks_like_anything_in_list: "Looks like you don't have anything in your list",
    generic_success: '{{code}} successfully confirmed',
    generic_success_canceled: '{{code}} successfully canceled',
    generic_error: '{{code}} error',
    fill_all_fields_and_search_an_item_configuration: 'Fill all fields and search an item configuration',
    encoding_success: 'Encoding successfully confirmed',
    encoding_success_none: 'Item already associated previously',
    read_some_identifier_to_start_verification: 'Read some identifier to start verification',
    warning_items_found: 'Warning items found',
    are_you_sure_you_want_to_confirm: 'Are you sure you want to confirm?',
    item_session_list_confirm: 'List confirmed successfully',
    operation_in_progress: 'Operation in progress, please wait...',
    sorting_error_zones: 'Origin zone not valued, impossible to continue with the ventilation',
    connection_in_progress: 'Connection in progress...',
    checklist_there_are: 'There are',
    checklist_missings: 'missing items',
    checklist_overqty: 'exceeding items',
    checklist_unexpecteds: 'unexpected items',
    checklist_confirm_mismatch_question: 'Are you sure you want to confirm anyway?',
    force_update_content: 'Are you sure you want to force send to SAP?',
    connection_to_station_in_progress: 'Connection to station in progress',
    tag_killed: 'Tag killed',
    tag_deleted: 'Tag deleted',
    write_tag: 'Write tag succesfully',
    not_ready: 'Not ready',
    readyf: 'Ready',
    nfcDecryption: 'NFC Decryption. Please keep the tag still',
    parcel_success: 'Parcel confirmed with success',
    sentry: {
      success: 'Thank you for your feedback! Your contribution helps us improve our service.'
    },
    confirm_force_shipment: 'Confirm shipment with PFAS',
    input_rfid_barcodes: 'Please input the RFID barcodes, separated by comma or new line',
    //valentino
    are_you_sure_to_clear_reads: 'Are you sure to clear reads?',
    are_you_sure_to_confirm: 'Are you sure to confirm?',
    inbound_conveyor_confirm_missing_message: 'There are still -missing- items to confirm, are you sure to confirm?',
    ///
    newVersionAvailable: 'New version available',
    pageWillReload: 'The page will reload automatically in 30 seconds',
    update_available_description: 'An update is available for this page. Please reload the page to apply the update.',
    deviceManagerUpdateTitle: 'Device Manager Update',
    deviceManagerUpdateDescription:
      'A new update is available for the Device Manager (software that integrates the RFID reader). Update now to ensure optimal operation.',
    deviceManagerUpdating: 'Device manager update in progress. Please wait for completion…',
    deviceManagerUpdateCompleted: 'Update completed successfully'
  },
  placeholder: {
    search_shipment_parcel: 'Search Shipment or Parcel',
    search_product_parcel: 'Search Product or Parcel'
  },
  confirm: {
    discrepancy_title: 'Parcel with discrepancies',
    discrepancy_message: 'Products with discrepancies were found. Are you sure you want to confirm the parcel?',
    confirm_delete_association: 'Are you sure you want to delete this item association?',
    confirm_delete_operation_title: 'Delete {{operation}}',
    confirm_delete_operation: 'Do you want to delete the operation? All items read will be lost.',
    confirm_delete_item_association: 'Are you sure you want to delete this item association?',
    confirm_kill_tag: 'Are you sure you want to kill tag {{tag}}?',
    confirm_operation_title: 'Confirm {{operation}}',
    confirm_operation_text: 'Do you want to confirm the operation?',
    confirm_operation_text_irreversible: 'Do you want to confirm the operation? This action cannot be reverted.',
    confirm_operation_text_partial_confirm: 'Sending reading to JDE. Are you sure to continue?',
    post_confirm_action_title: 'Continue',
    post_confirm_action_keep_input: 'Operation complete. Perform another operation?',
    post_confirm_action_change_input: 'Operation complete. Perform another operation?',
    confirm_close_asn_title: 'Close Confirmation',
    confirm_close_asn_text:
      'Some parcels have not been received. Are you sure you want to FORCE the closing of this shipment?',
    unpack: 'Parcel already packed. Do you want to proceed with the unpack and packing again?'
  },
  labels: {
    draft: 'Draft',
    ready_to_send: 'Ready to send',
    send_await: 'Pending',
    outbound_error: 'Outbound error',
    sent: 'Sent',
    in_inbound: 'In Inbound',
    receive_await: 'Pending',
    ready_to_receive: 'To receive',
    inbound_error: 'Inbound error',
    received: 'Received',
    in_transit: 'In transit'
  },
  serverError: {
    //moncler
    WRONG_ITEM_SUPPLIER: 'Some read articles cannot be taken over by the supplier.',
    QUANTITY_MISMATCH_ERROR: 'Quantities entered do not correspond to the quantities detected.',
    ZONE_MISMATCH_ERROR: 'Zone entered does not correspond to the zone detected.'
  },
  error_messages: {
    //moncler
    missing_workstation_place: 'Missing workstation place'
  },
  report_tbox: {
    umg: 'Umg',
    ddt_number: 'DDT N.',
    creation_date: 'Date',
    capi_mdc: 'Capi MDC',
    qt_totale: 'Total Qty',
    qt_accettata: 'Accepeted Qty',
    qt_scartata: 'Rejected Qty',
    supplier: 'Supplier',
    notes: 'Notes'
  },
  custom: {
    countTBoxParcel: 'Parcel',
    countTBoxDDT: 'DDT',
    BARCODE_READ: 'Barcode Read',
    RFID_START_READING: 'Start reading',
    RFID_STOP_READING: 'Stop reading',
    RESULT_EVENT: 'Result event',
    TRACE: 'Trace',
    INFO: 'Info',
    WARNING: 'Warning',
    ERROR: 'Error',
    deleteSession: 'Delete Parcel',
    deleteSessionMessage: 'Are you sure you want to delete the parcel?',
    deleteList: 'Delete DDT',
    deleteListMessage: 'Are you sure you want to delete the ddt?',
    WAM: 'SKU',
    sap_warning: 'SAP warning',
    total_list: 'Total list',
    total_parcel: 'Total parcel',
    order_number: 'Order number',
    addon: 'Addon',
    componentType: 'Component Type',
    //cucinelli
    barcode: 'Barcode BC',
    barcodeBCTrim: 'Barcode BC trim',
    barcodeJDE: 'Barcode JDE',
    barcodeROQ: 'Barcode ROQ',
    barcodeROQTrim: 'Barcode ROQ trim',
    class: 'Class',
    department: 'Department',
    dimensionSystem: 'Custom class',
    ean: 'EAN',
    eanIdPadre: 'Father EAN',
    itemId: 'Item Id',
    stagioneROQ: 'ROQ season',
    subclass: 'Model Type',
    subdepartment: 'Subdepartment',
    variantType: 'Typology ',
    rfidEnabled: 'RFID enabled',
    stato: 'Status',
    ricondizionamentoFornitore: 'Recondition supplier',
    codiceShelf: 'Stock Compartment',
    montante: 'Section',
    ripiano: 'Bin',
    scaffale: 'Bay',
    ASSOCIATED: 'Associated',
    NOT_ASSOCIATED: 'Not associated',
    REPLACED_ASSOCIATION: 'Replaced',
    identifiers: 'Identifiers',
    force_parcel: 'Force parcel',
    force_parcel_message: 'Are you sure you want to force this parcel?',
    pesa_collo: 'Weight neck',
    peso_kg: 'Weight (kg)',
    wrong_packaging_code: 'Wrong packing code',
    packaging_confirmed_no_weight: 'You are confirming a package without weight, do you want to continue?',
    attention: 'Attention',
    continue: 'Continue',
    model: 'Modello',
    sizeCode: 'Size code',
    sizeDescription: 'Size description',
    serial: 'Serial',
    identifier: 'Serial',
    product: 'Barcode BC',
    shift: 'Shift',
    destination: 'Destination',
    regDate: 'Date',
    listPosition: 'List position',
    list: 'List',
    customerName: 'Customer Name',
    regTime: 'Time',
    event: 'Event',
    customer: 'Customer',
    isCarryover: 'Carryover',
    carryoverValue: 'Carryover',
    madeIn: 'Made in',
    collectionCode: 'Collection',
    collectionDescription: 'Collection Description',
    collectionYear: 'Year',
    fatherProductValue: 'Father Product',
    genderValue: 'Gender',
    genderValueDescription: 'Gender Description',
    line: 'Brandline',
    lineDescription: 'Line Description',
    marchioDescription: 'Marchio Description',
    marchioValue: 'Marchio',
    merchandiseClassCode: 'Category',
    merchandiseClassDescription: 'Category Description',
    merchandiseSubclassCode: 'Sub-Category',
    merchandiseSubclassDescription: 'Sub-Category Description',
    modelPartColor: 'Model Part Color',
    modelPartColorSizeValue: 'Model Part Color Size',
    isPermanent: 'Permanent',
    permanentValue: 'Permanent',
    isSeasonal: 'Seasonal',
    seasonalValue: 'Seasonal',
    sleeveTypeValue: 'Sleeve Type',
    origin_location_mandatory_field_error: 'Origin location is required',
    transactionDate_mandatory_field_error: 'Transaction date is required',
    customerCode_mandatory_field_error: 'Customer code is required',
    tipology_field_error: 'Tipology is required',
    warehouseUsa_relabeling_mandatory_fields_error: 'Reballing is required',
    //valentino
    sconosciuto: 'UNKNOWN',
    prodottoSconosciuto: 'Unknown product',
    reads_ok: 'READS OK',
    tot_to_check: 'Total to check',
    //moncler
    confirm_force_received_text: 'This shipment has already been confirmed. Would you like to remove it from the list?',
    //louis vuitton
    tooManyTags: 'Too many tags',
    nfcDetectedPleaseUseProductControl: 'NFC tag detected. Please use the Product Control functionality.',
    GENERIC_ERROR: 'Generic Error - Please contact support via ContactIS',
    ECERTIFICATE_WORKORDER_NOT_FOUND: 'Workorder not found in eCertificate',
    ITEM_IN_ANOTHER_PARCEL: 'This item is already declared in an other parcel',
    ECERTIFICATE_WORKORDER_CREATED_BOT_NOT_COMPLETED: 'The workorder is created in eCertificate but not completed',
    INVALID_TIMESTAMP: 'Invalid timestamp',
    INVALID_SERIAL: 'Invalid serial number',
    INVALID_WORKORDER: 'Invalid workorder number',
    INVALID_SKU: 'Invalid SKU',
    INVALID_PARCEL_NUMBER: 'Invalid parcel number',
    SAP_UCS_NOT_CONFIRMED: 'UCS *{parcelCode} not confirmed at end of assembly line',
    SAP_REMOVABLE_SYNCHRO_NOT_PERFORMED: 'Removable synchro not performed',
    SAP_INCORRECT_OPERATION_STATUS: 'Operation status of UCS *{parcelCode} is not correct in SAP ME',
    SAP_COMPLETED_NOT_FOUND_IN_TNT: 'UCS already completed in SAP, not present in T&T',
    SAP_UCS_CANNOT_BE_COMPLETED: 'UCS *{parcelCode} cannot be completed in SAP ME/MII',
    SAP_WORKORDER_CLOSED: 'The workorder associated to UCS *{parcelCode} is closed in SAP MII',
    SAP_INTERNAL_ERROR: 'Technical error in SAP MII',
    SAP_TIMEOUT_BUT_OK_AGGREGATION: 'Timeout -> Aggregation is OK',
    LVSP_PARCEL_NUMBER_ALREADY_AGGREGATED: 'Parcel Number already aggregated in LVSP',
    LVSP_SERIAL_ALREADY_AGGREGATED: 'Serial Number already aggregated in previous in LVSP',
    LVSP_MISMATCH_EXPECTED_QUANTITY: 'Mismatch between scanned quantity and expected quantity in LVSP',
    LVSP_SKU_NOT_FOUND: 'Unknown SKU in LVSP',
    LVSP_SUPPLIER_NOT_FOUND: 'Supplier unknown in LVSP',
    TECHNICAL_ERROR: 'Technical issue - please contact support via ContactIS'
  },
  imbustatrice: {
    and: ' and ',
    unkwon_tag: 'Unkwon Tag',
    dynamic_tag: '{{role}} Tag',
    dynamic_tag_missing: '{{role}} Tag missing',
    too_many_dynamic_tags: 'Too many {{role}} tags',
    dynamic_tag_already_associated: '{{role}} tag already associated',
    wrong_tag: 'Wrong Tag',
    missing_tag: 'Tag missing'
  },
  identifier_error: {
    ALREADY_ASSOCIATED_TAG: 'Already associated',
    WRONG_QC_STATUS: 'Wrong quality control status',
    WRONG_QC_SIZE: 'Wrong quality control size',
    WRONG_QC_MODEL: 'Wrong quality control model',
    MISSING_MANDATORY_QC: 'Missing quality control',
    MISSING_PRODUCT_INFO: 'Missing product info',
    WRONG_TAG: 'Wrong tag',
    UNKNOWN_TAG: 'Unknown tag',
    WRONG_PRODUCT: 'Wrong product',
    MISSING_MANDATORY_TAG: 'Missing mandatory tag',
    MISSING_OPTIONAL_TAG: 'Missing optional tag',
    EXTRA_TAG: 'Extra tag',
    TAG_MISMATCH: 'Tag mismatch',
    PRODUCTIONORDERROW_MISMATCH: 'Mismatching Production Order Row Association',
    MISSING_SYSTEM_TAG: 'Unknown tag',
    UNEXPECTED_TAG: 'Unexpected Tag',
    NOT_EXISTING_CERTILOGO_TAG: 'Certilogo code not present in the system'
  },
  statuses: {
    RESTORE_VARIANT: 'RESTORE TYPOLOGY'
  },
  userSettings: {
    title: 'User settings',
    autostopAntennaTimeout: 'Timeout antennas stop (ms)',
    autostartAntenna: 'Autostart antenna',
    autoConfirmParcel: 'Auto confirm parcel',
    barcodeItemRead: 'Barcode item read'
  },
  attributes: {
    material: 'Material',
    workerCode: 'Worker Code',
    workerExternalCode: 'Worker External Code',
    workerIdentifier: 'Worker Identifier',
    workerDescription: 'Worker Description',
    workerType: 'Worker Type',
    orderCode: 'Order Code',
    sapQualityControlStatus: 'SAP Quality Control Status',
    quantityOkWorker: 'OK WORKER QTY',
    quantityKoWorker: 'KO WORKER QTY',
    quantityOkRepair: 'OK REPAIR QTY',
    quantityOkValdagno: 'OK VALDAGNO QTY',
    quantityOkFirstInbound: 'OK FIRST INBOUND QTY',
    quantityOkRis: 'RISK QTY',
    quantityKoValdagno: 'KO VALDAGNO QTY',
    quantitySecondValdagno: 'SECOND CHOICE QTY',
    quantityOkLining: 'OK LINING QTY',
    quantityKoLining: 'KO LINING QTY'
  },
  title: {
    report_problem: 'Report a problem'
  },
  subtitle: {
    report_problem: 'Describe the problem you encountered or suggest an improvement'
  },
  label: {
    name: 'Name',
    email: 'Email',
    description: 'Problem description',
    close: 'Close',
    submit: 'Submit feedback'
  },
  success: {
    message: 'Thank you for your feedback!'
  },
  button: {
    submit: 'Submit feedback'
  }
}
